@use '@adnova/jf-ng-components/config' as *;
@forward '@adnova/jf-ng-components/base';


@layer material, base;

@import 'material-icons/iconfont/material-icons.css';

body {
  background-color: #fff;
}

h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

mat-form-field {
  button {
    &.mat-icon-button:enabled[mat-icon-button] {
      color: currentColor !important;
    }
  }
}

button {

  &.mat-button,
  &.mat-raised-button,
  &.mat-flat-button,
  &.mat-stroked-button {
    box-shadow: none !important;
    line-height: 38px; // INFO: 38px plus je 1px Border oben und unten => 40px Gesamthöhe

    > .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.mat-button:enabled,
  &.mat-raised-button:enabled,
  &.mat-flat-button:enabled {
    &:hover,
    &:focus {
      background-color: var(--default-hover) !important;

      &[color="accent"] {
        background-color: var(--accent-hover) !important;
      }

      &[color="primary"] {
        background-color: var(--primary-hover) !important;
      }
    }
  }

  &.mat-stroked-button:enabled,
  &.mat-icon-button:enabled {
    &[mat-icon-button] {
      color: var(--accent) !important;
    }

    &:hover,
    &:focus {
      border-color: var(--accent-hover) !important;
      color: var(--accent-hover) !important;

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}

.jf-snackbar {
  background-color: #323232;
  color: white;
}
